import ADLink from '/static/images/customer-logo/ADLink.png'
import Advantech from '/static/images/customer-logo/Advantech.png'
import Aurora from '/static/images/customer-logo/Aurora.png'
import Edifian from '/static/images/customer-logo/Edifian.png'
import EverYoung from '/static/images/customer-logo/EverYoung.png'
import GIS from '/static/images/customer-logo/GIS.png'
import GCloud from '/static/images/customer-logo/Going-cloud.png'
import Ile from '/static/images/customer-logo/Ile.png'
import KangTai from '/static/images/customer-logo/KangTai.png'
import League from '/static/images/customer-logo/P-League.png'
import Toronto from '/static/images/customer-logo/Pride-Toronto.png'
import Retens from '/static/images/customer-logo/Retens.png'
import TWTC from '/static/images/customer-logo/Taipei-World-Trade-Center.png'
import TCI from '/static/images/customer-logo/TCI.png'
import Telexpress from '/static/images/customer-logo/Telexpress.png'
import Trinax from '/static/images/customer-logo/Trinax.png'
import YuanTa from '/static/images/customer-logo/YuanTa.png'

const allCustomerList = [
  {
    src: ADLink,
    alt: 'ADLink'
  },
  {
    src: Advantech,
    alt: 'Advantech'
  },
  {
    src: Aurora,
    alt: 'Aurora'
  },
  {
    src: Edifian,
    alt: 'Edifian'
  },
  {
    src: EverYoung,
    alt: 'Ever Young'
  },
  {
    src: GIS,
    alt: 'GIS'
  },
  {
    src: GCloud,
    alt: 'Going Cloud'
  },
  {
    src: Ile,
    alt: 'Ile'
  },
  {
    src: KangTai,
    alt: 'Kang Tai'
  },
  {
    src: League,
    alt: 'P.League'
  },
  {
    src: Toronto,
    alt: 'Pride-Toronto'
  },
  {
    src: Retens,
    alt: 'Retens'
  },
  {
    src: TWTC,
    alt: 'TWTC'
  },
  {
    src: TCI,
    alt: 'TCI'
  },
  {
    src: Telexpress,
    alt: 'Telexpress'
  },
  {
    src: Trinax,
    alt: 'Trinax'
  },
  {
    src: YuanTa,
    alt: 'YuanTa'
  }
]

export { allCustomerList }
