import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Seo from 'components/seo'
import Layout from 'layouts/index'
// import ContactUsForm from 'components/form/contactUsForm'
import HsGetDemoForm from 'components/hubspot/getDemoForm'
import { allCustomerList } from '/static/data/customer'
// import { options } from '/static/data/contactUs.static'

const ContactUs = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: 'Contact Mlytics Expert',
            metaDescription: `Looking to enhance your app's global delivery? Contact Mlytics’ experts for customized solutions to boost your business’s performance and scalability.`,
            metaUrl: 'https://www.mlytics.com/contact-us',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        {/* Contact Us Info Section */}
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full relative flex flex-col lg:flex-row items-center lg:items-start justify-between pb-[60px] gap-10">
            <div className="flex-1 max-w-[600px] md:w-[600px]">
              <div className="w-full flex flex-col gap-5 border-b border-solid border-black-80 pb-10">
                <h1 className="h1-page-title text-black-100">Contact Our Experts</h1>
                <p className="h5-subtext-medium text-black-70">
                  Contact our Experts to learn more about Mlytics’s API-centric platform and service that create the
                  business values for your special use case.
                </p>
                <p className="h5-subtext-medium text-black-70">
                  If you’re planning to use Mlytics’s API services, you can refer to{' '}
                  <a
                    href="https://developer.mlytics.com/v2.0-MPI/docs/getting-started"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    this API document
                  </a>
                  .
                </p>
              </div>
              <div className="flex flex-col py-[60px] gap-[60px]">
                <div className="flex flex-col gap-5">
                  <p className="h3-card-title text-black-90">Provide Tailored Solutions for Your Business</p>
                  <p className="h5-subtext-medium text-black-70">
                    Mlytics provides value-added solutions including API platforms and solutions for you to connect with
                    the world.
                  </p>
                </div>
                <div className="flex flex-col gap-5">
                  <p className="h3-card-title text-black-90">Delivering Seamless Experiences for All</p>
                  <p className="h5-subtext-medium text-black-70">
                    We’re committed to transforming your experience. By redefining delivery, we’re reshaping
                    expectations and unlocking new possibilities.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-1 w-[320px] px-3 py-5 max-w-[528px] md:w-[500px] bg-white border border-black-90 rounded-lg md:p-10 flex flex-col gap-10">
              <HsGetDemoForm />
            </div>
          </div>
          {/* Trusted Companies Section */}
          <div className="relative py-[60px] w-full flex items-center justify-center">
            <div className="flex flex-col items-center w-full gap-10">
              <h2 className="h2-block-title text-black-100 text-center">Trusted by Fortune 50 Companies</h2>
              <div className="flex flex-col gap-[60px] w-full">
                <p className="h4-content-text text-black-70 text-center">Some of our delightful customers</p>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-5 justify-items-center w-full">
                  {allCustomerList.map((item) => (
                    <img key={item.alt} src={item.src} alt={item.alt} height="85" width="170" />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default ContactUs

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
